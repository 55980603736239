@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Medium.eot');
    src: url('BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Medium.woff2') format('woff2'),
        url('BrandonGrotesque-Medium.woff') format('woff'),
        url('BrandonGrotesque-Medium.ttf') format('truetype'),
        url('BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('BrandonGrotesque-Light.eot');
    src: url('BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('BrandonGrotesque-Light.woff2') format('woff2'),
        url('BrandonGrotesque-Light.woff') format('woff'),
        url('BrandonGrotesque-Light.ttf') format('truetype'),
        url('BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bon Vivant Serif';
    src: url('BonVivantSerif.eot');
    src: url('BonVivantSerif.eot?#iefix') format('embedded-opentype'),
        url('BonVivantSerif.woff2') format('woff2'),
        url('BonVivantSerif.woff') format('woff'),
        url('BonVivantSerif.ttf') format('truetype'),
        url('BonVivantSerif.svg#BonVivantSerif') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santorini';
    src: url('Santorini-Regular.eot');
    src: url('Santorini-Regular.eot?#iefix') format('embedded-opentype'),
        url('Santorini-Regular.woff2') format('woff2'),
        url('Santorini-Regular.woff') format('woff'),
        url('Santorini-Regular.ttf') format('truetype'),
        url('Santorini-Regular.svg#Santorini-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

